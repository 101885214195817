import React, { useEffect, useState } from "react";
import eyeIcon from "./../../../assets/img/eye.svg";
import eyeSlashIcon from "./../../../assets/img/eye-slash.svg";
import "./LoginForm.scss";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { apiService } from "../../../services/apiService";
import PageLoader from "../../../shared/page-loader";

interface LoginResponse {
  statusCode: null | number;
  succeeded: boolean;
  errors: null | string;
  message: null | string;
  data: null | TokenResponse;
}
interface TokenResponse {
  accessToken: null | string;
  refreshToken: null | string;
}
interface LoginForm {
  authService: {
    login: () => Promise<void>;
  };
}

const LoginForm: React.FC<LoginForm> = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | null>(
    null
  );
  const [LoginError, setLoginError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [formSubmission, setFormSubmission] = useState(true);
  const navigate = useNavigate();

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please provide a valid email.");
    } else {
      setEmailError((prev) => "");
    }
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password.trim() === "") {
      setPasswordError("Please provide a password.");
    } else if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long.");
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one special character (@$!%*?&), and one numeric character."
      );
    } else {
      setPasswordError((prev) => "");
      // validateForm();
    }
  };

  // Run validateForm when passwordError updates
  useEffect(() => {
    validateForm();
  }, [passwordError, emailError]);

  useEffect(() => {
    if (email) validateEmail();
    if (password) validatePassword();
  }, [email, password]);

  const validateForm = () => {
    if (
      emailError === "" &&
      passwordError === "" &&
      email !== "" &&
      password !== ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("userSignup", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const endpoint = "auth/account/Login";
    const postData = {
      Email: email,
      Password: password,
    };

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, postData);
          setLoginResponse(response);
          setData(response);
          const id = response.objectId;
          setLoading(false);
          navigate(`/`);
          setFormSubmission(true);
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            // Conflict error
            toast.error("invalid email or password.");
          } else {
            // Handle other specific error cases
            toast.error("An error occurred while signing up.");
          }
          setFormSubmission(true);
        }
      }, 1000); // 1000 milliseconds (1 second)
    } finally {
      console.log(loginResponse);
      setLoading(false);
    }
  };

  const handleSignup = () => {
    navigate(`/signup`);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validateEmail();
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    validatePassword();
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <h1>Sign in</h1>
      <p className="lead m-0">
        Welcome back. Always remember - the more details you fill out, the
        better the chances to land your next client.
      </p>
      <p className="text-muted small text-muted mb-5">
        Don't have an account?{" "}
        <a className="text-primary cursor-pointer" onClick={handleSignup}>
          Create one!
        </a>
      </p>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="mb-3">
          <input
            type="email"
            placeholder="Email"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            required
            value={email}
            onChange={handleEmailChange}
            onBlur={validateEmail}
            autoComplete="new-email"
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>
        <div className="mb-3 password-container">
          <i
            className="eye-icon"
            onClick={() => setHidePassword(!hidePassword)}
          >
            <img src={hidePassword ? eyeSlashIcon : eyeIcon} alt="" />
          </i>
          <input
            type={hidePassword ? "password" : "text"}
            placeholder="Password"
            className={`form-control ${passwordError ? "is-invalid" : ""}`}
            required
            value={password}
            onChange={handlePasswordChange}
            onBlur={validatePassword}
            autoComplete="new-password"
          />
          <div className="invalid-feedback">{passwordError}</div>
          {/* {passwordError ? (
            
          ) : (
            <div className="form-text">
              Password must contain at least one uppercase letter, one special
              character (@$!%*?&), and one numeric character.
            </div>
          )} */}
        </div>
        <NavLink to="/forgot-password" className="mb-3">
          <p>Forgot password?</p>
        </NavLink>
        <div className="d-grid gap-2">
          {formSubmission ? (
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!isFormValid}
            >
              Login
            </button>
          ) : (
            <button className="btn btn-primary" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                aria-hidden="true"
              ></span>
              <span className="ms-2" role="status">
                Login
              </span>
            </button>
          )}
        </div>
        <p className="mt-3 text-center">
          <NavLink to="/">Back to home</NavLink>
        </p>
      </form>
    </>
  );
};

export default LoginForm;
