import React, { useState, useEffect } from "react";
import { apiService } from "../../../services/apiService";
import { toast } from "react-toastify";
import "../Header.scss";
import { jwtDecode } from "jwt-decode";


const Verification: React.FC = () =>{
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );

  const [formSubmission, setFormSubmission] = useState(true);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default action
    let primaryEmail = "";
    const currentUser = localStorage.getItem("userSignup") as string;
    if (currentUser && currentUser !== "[]") {
      const user = JSON.parse(currentUser);
      const userDecoded = jwtDecode(user.data.accessToken) as any;
      if (userDecoded && userDecoded.sub) {
        primaryEmail = userDecoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
        ];
      }
    }
    const formData = {
      Email: primaryEmail,
    };

    try {
      setFormSubmission(false);

      const endpoint = `auth/account/request-confirm-email`;
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, formData);
          if (response) {
            setSubmissionStatus("success");
            toast.success("Verification email resent successfully!");
          }
        } catch (error) {
          console.error(error);
          toast.error("Failed to resend verification email.");
        } finally {
          setFormSubmission(true);
        }
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Error handling the request.");
      setFormSubmission(true);
    }
  };

  return (
    <>
      <div
        className="alert alert-warning alert-dismissible fade show verification-alert"
        role="alert"
      >
        <div className="container text-center">
          <strong>Verify Your Email:</strong> Your company will be listed once
          email is verified.{" "}
          <button
            onClick={handleSubmit}
            disabled={!formSubmission}
            className="btn btn-link text-info verification-button p-0"
          >
            {formSubmission ? (
              "Resend verification email"
            ) : (
              <>
                <div className="spinner-border text-info" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>{" "}
                Sending
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Verification;
