import React, { useState, useEffect } from "react";
import { apiService } from "../../services/apiService";
import Cards from "../../shared/Cards";
import CompanyResponse from "../../Models/CompanyResponse";
import SearchState from "../../Models/SearchState";
import addImage from "../../assets/img/place-ad-banner07.png";
import SearchBanner from "../../components/Banner/SearchBanner";

const Featured: React.FC = () => {
  const [state, setState] = useState<SearchState>({
    companyData: {} as CompanyResponse,
    isLoading: true,
    pageNumber: 1,
    PageSize: 18,
    OrderByColumn: "name",
    OrderBy: "ASC",
  });
  const [moreCompanies, setMoreCompanies] = useState(true);
  const [ownCompany, setOwnCompany] = useState<any>(); // Adjust type as per your company data structure
  const IsFeatured = true;

  const adLink = {
    url: "https://quantumcph.com/",
    image: addImage,
    isAdd: true,
  };

  useEffect(() => {
    const loadCompaniesData = async () => {
      const userCompanyData = JSON.parse(
        localStorage.getItem("userCompany") || "{}"
      );

      // Set own company only if it exists and it's the first page load
      if (userCompanyData && userCompanyData.name && state.pageNumber === 1) {
        setOwnCompany(userCompanyData);
      }

      const pageToLoad =
        userCompanyData && userCompanyData.name
          ? state.PageSize - 1
          : state.PageSize;

      const endpoint = `company/company?IsFeatured=${IsFeatured}&PageNumber=1&PageSize=${pageToLoad}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;

      try {
        const featuredCompanies = await apiService.get(endpoint);

        let updatedCompanyData: CompanyResponse = { ...featuredCompanies };

        if (userCompanyData && userCompanyData.name && state.pageNumber === 1) {
          const middleIndex = Math.floor(featuredCompanies.items.length / 2);
          featuredCompanies.items.splice(middleIndex, 0, userCompanyData);
        }

        setState((prevState) => ({
          ...prevState,
          companyData: updatedCompanyData,
          isLoading: false,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    loadCompaniesData();
  }, []);

  const handleLoadMore = async () => {
    const newPageNumber = state.pageNumber + 1;

    const pageToLoad =
      newPageNumber === 2 ? state.PageSize : state.PageSize + 1;

    const endpoint = `company/company?IsFeatured=${IsFeatured}&PageNumber=${newPageNumber}&PageSize=${state.PageSize}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;

    try {
      const featuredCompanies = await apiService.get(endpoint);

      let updatedCompanyData: CompanyResponse = {
        items: [],
        pageNumber: featuredCompanies.pageNumber,
        totalPages: featuredCompanies.totalPages,
        totalCount: featuredCompanies.totalCount,
        hasPreviousPage: featuredCompanies.hasPreviousPage,
        hasNextPage: featuredCompanies.hasNextPage,
      };

      if (newPageNumber === 2) {
        const middleIndex = Math.floor(featuredCompanies.items.length / 2);
        featuredCompanies.items.splice(middleIndex, 0, adLink);
      }

      setState((prevState) => ({
        ...prevState,
        companyData: {
          ...prevState.companyData,
          items: [...prevState.companyData.items, ...featuredCompanies.items],
          pageNumber: featuredCompanies.pageNumber,
          totalPages: featuredCompanies.totalPages,
          totalCount: featuredCompanies.totalCount,
          hasPreviousPage: featuredCompanies.hasPreviousPage,
          hasNextPage: featuredCompanies.hasNextPage,
        },
        pageNumber: newPageNumber,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SearchBanner />
      <div className="container py-6">
        <h2 className="text-center sectionHeading">
          Explore my top suggestions for you!
        </h2>
        {state.companyData && (
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-3">
            {state.companyData?.items
              ? state.companyData.items.map((companyData, index) => (
                  <div key={index} className="col">
                    <Cards companyData={companyData} />
                  </div>
                ))
              : !state.isLoading && <p>No companies found</p>}
          </div>
        )}
        {state.isLoading && <p>Loading...</p>}
        {!state.isLoading &&
          state.pageNumber < state.companyData.totalPages && (
            <div className="d-grid gap-2 d-flex justify-content-center mt-5">
              {moreCompanies ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleLoadMore}
                >
                  Show More
                </button>
              ) : (
                <button className="btn btn-primary" type="button" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="ms-2" role="status">
                    Loading
                  </span>
                </button>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default Featured;
