import React, { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";
import { useImage } from "../../../context/ImageContext";
import UploaderImage from "./../../../assets/img/placeholder-logo.png";

interface CompanyImageProps {
  imageUrl: string | null;
  companyId: string | null;
}

const CompanyImage: React.FC<CompanyImageProps> = ({ imageUrl, companyId }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string | null>(imageUrl);
  const [loading, setLoading] = useState(false);
  const [formSubmission, setFormSubmission] = useState(true);
  const { setImage } = useImage(); // Access the setImage function from the context

  useEffect(() => {
    setUploadedImage(imageUrl);
  }, [imageUrl]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      uploadImage(e.target.files[0]);
    }
  };

  const uploadImage = async (image: File) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("CompanyId", companyId || "");
    formData.append("NewPicture", image);
    formData.append("ImageType", "0"); // Replace with the appropriate value
    formData.append("Action", "0"); // Replace with the appropriate value
    const endpoint = "company/company/UpdateImage";
    try {
      const response = await apiService.putMultipart(endpoint, formData);
      // console.log("POST Response:", response);
      setLoading(false);
      const newImage = URL.createObjectURL(image);
      setUploadedImage(newImage);
      setImage(newImage);
      toast.success("Image uploaded successfully!");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      // console.error("POST Error:", error);
      setLoading(false);
      toast.error("Image uploading failed!");
    }
  };
  const removeCompanyImage = async () => {
    setFormSubmission(false);
    setTimeout(async () => {
      try {
        const endpoint = "company/company/DeleteImage";
        await apiService.deleteReference(endpoint, companyId || "");
        setUploadedImage(null);
        setImage("");
        toast.success("Logo removed successfully!");
      } catch (error) {
        toast.error("Failed to remove Logo!");
      }
      setFormSubmission(true);
    }, 1000);
  };
  return (
    <>
      <div className="d-flex align-items-center mb-3 editCompanyLogo">
        <div className="p-2 companyLogo">
          {uploadedImage ? (
            <img
              src={uploadedImage}
              className="img-fluid rounded"
              alt="Company Logo"
            />
          ) : (
            <img
              src={UploaderImage}
              className="img-fluid rounded"
              alt="Upload Logo"
            />
          )}
        </div>
        <div className="p-2 col">
          {formSubmission ? (
            <label className="btn btn-outline-primary btn-sm ms-2">
              <input
                type="file"
                onChange={handleFileChange}
                disabled={!formSubmission}
              />
              {uploadedImage ? "Change" : "Select Picture"}
            </label>
          ) : (
            <button
              type="button"
              disabled={!formSubmission}
              className="btn btn-outline-primary btn-sm ms-2"
            >
              {uploadedImage ? "Change" : "Select Picture"}
            </button>
          )}
          {uploadedImage ? (
            <button
              type="button"
              onClick={removeCompanyImage}
              disabled={!formSubmission}
              className="btn btn-outline-danger btn-sm ms-2"
            >
              {formSubmission ? "Remove" : "Removing..."}
            </button>
          ) : (
            ""
          )}

          {/* {loading ? "Uploading..." : "Submit"}     */}
        </div>
      </div>
    </>
  );
};

export default CompanyImage;
