import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./Filters.scss";

import logo from "./../../assets/img/logo-colored.svg";
import Select from "react-select";
import { apiService } from "../../services/apiService";
import TechnologyResponse from "../../Models/TechnologyResponse";
import ServiceResponse from "../../Models/ServicesResponse";

interface FilterOption {
  label: string;
  value: string;
}
interface Service {
  id: string;
  name: string;
  createdBy: string | null;
  createdOn: string | null;
  requestUserId: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  description: string | null;
}
interface ChoosenServices {
  objectIds: any[];
}
interface Technology {
  id: string;
  name: string;
  createdBy: string | null;
  createdOn: string | null;
  requestUserId: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  description: string | null;
}
interface ChoosenTechnologies {
  objectIds: any[];
}
const Filters: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [technologiesList, setTechnologiesList] = useState<Technology[]>([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<Service[]>(
    []
  );
  const [companyServices, setCompanyServices] = useState<ChoosenServices[]>([]);
  const [companyTechnologies, setCompanyTechnologies] = useState<
    ChoosenTechnologies[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [technologies, setTechnologies] = useState<TechnologyResponse>({
    items: [],
  });
  const [services, setServices] = useState<ServiceResponse>({
    items: [],
  });
  const servicesEndpoint = `company/Service`;
  useEffect(() => {
    apiService
      .get(servicesEndpoint)
      .then((response) => {
        setServicesList(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const technologiesendpoint = `company/Technology`;
  useEffect(() => {
    apiService
      .get(technologiesendpoint)
      .then((response) => {
        setTechnologiesList(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const serviceOptions = servicesList.map((service) => ({
    value: service.id,
    label: service.name,
  }));
  const technologyOptions = technologiesList.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedServices(selectedOptions);
    const selectedIds = selectedOptions.map((option: any) => option.value);
    const selectedLabels = selectedOptions.map((option: any) => option.label);
    handleFilterChange("services", selectedLabels.join(","));
    const companyServicesLoad: ChoosenServices = {
      objectIds: selectedIds,
    };
    setCompanyServices([companyServicesLoad]);
  };

  const handleTechnologyChange = (selectedOptions: any) => {
    setSelectedTechnologies(selectedOptions);
    const selectedIds = selectedOptions.map((option: any) => option.value);
    const selectedLabels = selectedOptions.map((option: any) => option.label);
    handleFilterChange("technology", selectedLabels.join(","));
    const companyTechnologiesLoad: ChoosenTechnologies = {
      objectIds: selectedIds,
    };
    setCompanyTechnologies([companyTechnologiesLoad]);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterKeys = [
      "searchkey",
      "location",
      "size",
      "technology",
      "services",
    ];
    const newFilters: Record<string, string> = {};

    filterKeys.forEach((key) => {
      const value = searchParams.get(key) || "";
      newFilters[key] = value;
    });

    setFilters(newFilters);

    apiService
      .get("company/Technology")
      .then((response) => {
        setTechnologies({
          ...technologies,
          items: response,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    apiService
      .get("company/Service")
      .then((response) => {
        setServices({
          ...services,
          items: response,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const employeeRangeOptions = [
    { value: "0", label: "1-10" },
    { value: "1", label: "11-50" },
    { value: "2", label: "51-100" },
    { value: "3", label: "101-200" },
    { value: "4", label: "201-500" },
    { value: "5", label: "501-1000" },
    { value: "6", label: "1000+" },
  ];
  const handleFilterChange = (filterKey: string, value: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterKey]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const queryParams = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value) queryParams.set(key, value);
    });

    const newSearch = queryParams.toString();
    navigate({ search: newSearch });
  };

  return (
    <div
      className="offcanvas offcanvas-start"
      id="offcanvasResponsive"
      aria-labelledby="offcanvasResponsiveLabel"
    >
      <div className="offcanvas-header">
        {/* <img src={logo}  className="offcanvas-title" alt="" /> */}
        <h5 className="px-3">Advanced Search</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasResponsive"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-0">
        <aside className="filters">
          <form onSubmit={handleSubmit}>
            <ul className="list-group list-group-flush">
              {/* <li className="list-group-item bg-transparent">
                <h5>Advanced Search</h5>
              </li> */}
              <li className="list-group-item bg-transparent">
                <label className="form-label">Search</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Keywords"
                    value={filters["searchkey"]}
                    onChange={(e) =>
                      handleFilterChange("searchkey", e.target.value)
                    }
                  />
                </div>
              </li>

              {/* Location filter */}
              <li className="list-group-item bg-transparent">
                <label className="form-label">Location</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    value={filters["location"]}
                    onChange={(e) =>
                      handleFilterChange("location", e.target.value)
                    }
                  />
                </div>
              </li>

              {/* Technologies filter */}
              {/* <li className="list-group-item bg-transparent">
            <label className="form-label">Technologies</label>
            <div className="input-group input-group-sm">
              <input
                type="text"
                className="form-control"
                placeholder="iOS, .Net, Android"
                value={filters["technology"]}
                onChange={(e) =>
                  handleFilterChange("technology", e.target.value)
                }
              />
            </div>
            <div className="">
              {technologies?.items?.length > 0 &&
                technologies.items.map((technology) => (
                  <span
                    key={technology.id}
                    className={`badge ${
                      filters["technology"] === technology.name && "active"
                    }`}
                    onClick={() =>
                      handleFilterChange("technology", technology.name)
                    }
                  >
                    {technology.name}
                  </span>
                ))}
            </div>
          </li> */}

              {/* Services filter */}
              <li className="list-group-item bg-transparent">
                <label className="form-label">Services</label>
                {loading ? (
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Select"
                  />
                ) : (
                  <Select
                    isMulti
                    name="services"
                    options={serviceOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary50: "#ffa142",
                        primary25: "#ffbf80",
                        primary: "#ff7f00",
                      },
                    })}
                    onChange={handleSelectChange}
                  />
                )}
              </li>

              {/* Technologies filter */}
              <li className="list-group-item bg-transparent">
                <label className="form-label">Technologies</label>
                {loading ? (
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Select"
                  />
                ) : (
                  <Select
                    isMulti
                    name="technologies"
                    options={technologyOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary50: "#ffa142",
                        primary25: "#ffbf80",
                        primary: "#ff7f00",
                      },
                    })}
                    onChange={handleTechnologyChange}
                  />
                )}
              </li>

              {/* Employees filter */}
              <li className="list-group-item bg-transparent">
                <label className="form-label">Employees</label>
                <div className="input-group align-items-start">
                  <div className="form-control border-0 p-0 pt-1 bg-transparent">
                    {employeeRangeOptions.map((option) => (
                      <div
                        className="form-check form-check-inline"
                        key={option.value}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="strengthOptions"
                          id={option.value}
                          value={option.value}
                          checked={filters["size"] === option.value}
                          onChange={() =>
                            handleFilterChange("size", option.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={option.value}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </li>
              <li className="d-grid gap-2 p-3 filterButton">
                <button
                  className="btn btn-primary"
                  type="submit"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasResponsive"
                  aria-controls="offcanvasResponsive"
                >
                  Search
                </button>
              </li>
            </ul>
          </form>
        </aside>
      </div>
    </div>
  );
};

export default Filters;
