import React, { useEffect, useState } from "react";
import eyeIcon from "./../../../assets/img/eye.svg";
import eyeSlashIcon from "./../../../assets/img/eye-slash.svg";
import "./EmailConfirm.scss";
import { toast } from "react-toastify";
import { useSearchParams, NavLink, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import PageLoader from "../../shared/page-loader";
import Logo from "./../../assets/img/logo-colored.svg";
import tickmark from "./../../assets/img/tick-mark.svg";

const EmailConfirmForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const code = searchParams.get("code");
  const [emailVerified, setEmailVerified] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formSubmission, setFormSubmission] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      //setPageLoading(false);

    const endpoint = "auth/account/confirm-email";
    const postData = {
      UserId: userId,
      Code: code
    };

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, postData);
          if (response) {
            setEmailVerified(true);
          }
          setLoading(false);
          toast.success("your email verified.");
          navigate(`/`);
          setFormSubmission(true);
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            // Conflict error
            toast.error(error.message);
          } else {
            // Handle other specific error cases
            toast.error("An error occurred");
          }
          setFormSubmission(true);
        }
      }, 1000); // 1000 milliseconds (1 second)
    } finally {
      setLoading(false);
    }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);


  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid onboarding">
        <div className="row">
          <div className="col-md-6 logo-section">
            <NavLink to="/">
              <img src={Logo} alt="" className="align-middle d-inline-block" />
            </NavLink>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4">
            {emailVerified ? (
              <div className="content p-5">
                <div className="content p-5 text-center">
                  <div id="tick_mark">
                    <img src={tickmark} id="path_637" />
                  </div>
                  <h1>Email Verified!</h1>
                  <p className="text-muted mb-5">
                    Your email address verified.
                  </p>
                  <p className="mt-3 text-center">
                    <NavLink to="/">Back to home</NavLink>
                  </p>
                </div>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailConfirmForm;
