import React, { useEffect, useState } from "react";
import eyeIcon from "../../assets/img/eye.svg";
import eyeSlashIcon from "../../assets/img/eye-slash.svg";
import "./ResetPassword.scss";
import { toast } from "react-toastify";
import { useSearchParams , NavLink, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import PageLoader from "../../shared/page-loader";
import Logo from "./../../assets/img/logo-colored.svg";

interface ResetPasswordResponse {
  statusCode: null | number;
  succeeded: boolean;
  errors: null | string;
  message: null | string;
  data: null | TokenResponse;
}
interface TokenResponse {
  accessToken: null | string;
  refreshToken: null | string;
}
interface ResetPasswordForm {}
interface AuthProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
  };
}
const ResetPasswordForm: React.FC<AuthProps> = (props) => {
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("userEmail");
  const userCode = searchParams.get("userCode");
  const [pageLoading, setPageLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [formSubmission, setFormSubmission] = useState(true);
  const navigate = useNavigate();

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password.trim() === "") {
      setPasswordError("Please provide a password.");
    } else if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long.");
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one special character (@$!%*?&), and one numeric character."
      );
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = (value: string) => {
    if (value.trim() === "") {
      setConfirmPasswordError("Please confirm the password.");
    } else if (value !== password) {
      setConfirmPasswordError("Both passwords should match.");
    } else {
      setConfirmPasswordError("");
    }
    validateForm();
  };

  useEffect(() => {
    if (password) validatePassword();
    if (confirmPassword) validateConfirmPassword(confirmPassword);
  }, [password, confirmPassword]);

  const validateForm = () => {
    if (
      passwordError === "" &&
      confirmPasswordError === "" &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [userEmail, userCode]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const endpoint = "auth/account/reset-password";
    const postData = {
      Email: userEmail,
      Code: userCode,
      NewPassword: password
    };

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, postData);
          if (response) {
            setLoading(false);
            toast.success(response.message);
            navigate(`/login`);
            setFormSubmission(true);
          }
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            // Conflict error
            toast.error("Company already exists!");
          } else {
            // Handle other specific error cases
            toast.error("An error occurred while signing up.");
          }
          setFormSubmission(true);
        }
      }, 1000); // 1000 milliseconds (1 second)
    } finally {
      setLoading(false);
    }
  };
  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    validatePassword();
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid onboarding">
        <div className="row">
          <div className="col-md-6 logo-section">
            <NavLink to="/">
              <img src={Logo} alt="" className="align-middle d-inline-block" />
            </NavLink>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4">
            <div className="content p-5">
              <h1>Reset Password?</h1>
              <p className="lead mb-5">
                Please enter your registered email below to change your
                password!
              </p>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="mb-3 password-container">
                  <i
                    className="eye-icon"
                    onClick={() => setHidePassword(!hidePassword)}
                  >
                    <img src={hidePassword ? eyeSlashIcon : eyeIcon} alt="" />
                  </i>
                  <input
                    type={hidePassword ? "password" : "text"}
                    placeholder="Password"
                    className={`form-control ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    onBlur={validatePassword}
                    autoComplete="new-password"
                  />
                  {passwordError ? (
                    <div className="invalid-feedback">{passwordError}</div>
                  ) : (
                    <div className="form-text">
                      Password must contain at least one uppercase letter, one
                      special character (@$!%*?&), and one numeric character.
                    </div>
                  )}
                </div>
                <div className="mb-3 password-container">
                  <i
                    className="eye-icon"
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  >
                    <img
                      src={hideConfirmPassword ? eyeSlashIcon : eyeIcon}
                      alt=""
                    />
                  </i>
                  <input
                    type={hideConfirmPassword ? "password" : "text"}
                    placeholder="Confirm password"
                    className={`form-control ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      validateConfirmPassword(event.target.value)
                    }
                    autoComplete="new-password"
                  />
                  {confirmPasswordError && (
                    <div className="invalid-feedback">
                      {confirmPasswordError}
                    </div>
                  )}
                </div>
                <div className="d-grid gap-2">
                  {formSubmission ? (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={!isFormValid}
                    >
                      Reset
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2" role="status">
                        Reset
                      </span>
                    </button>
                  )}
                </div>
                <p className="mt-3 text-center">
                  <NavLink to="/">Back to home</NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
