import { useParams, useLocation, Link } from "react-router-dom";
import Contact from "../../shared/Contact";
import ServicesList from "../../shared/ServicesList";
import "./CompanyDetail.scss";
import logo from "./../../assets/img/placeholder-logo.png";
import Company from "../../Models/Company";
import CompanySize from "../../Models/CompanySize";
import IndustryType from "../../Models/IndustryType";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/apiService";
import CompanyBanner from "../../components/Banner/CompanyBanner";
import { NavLink, useNavigate } from "react-router-dom";
import { User } from "oidc-client";
import alvaAds from "./../../assets/img/place-ad-banner06.png";
import Technologies from "./../EditProfile/Components/Technologies";
import TechnologiesList from "../../shared/TechnologiesList";
import bannerAd from "./../../assets/img/place-ad-banner05.png";
import ReferenceCard from "../../shared/ReferenceCard";
import Claim from "../../shared/Claim";
import Spinner from "../../shared/Spinner";
import PageLoader from "./../../shared/page-loader";
interface RouteParams {
  id: string;
}
interface DetailsProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
    getUser: () => Promise<User | null>;
  };
}
const CompanyDetail: React.FC<DetailsProps> = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [user, setUser] = useState<User | null>();
  const [companyId, setCompanyId] = useState("");
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { id } = useParams<{ id: string }>();
  const webLocation = useLocation();
  const [company, setCompany] = useState<Company | null>(null);
  const [references, setReferences] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSameCompany, setIsSameCompany] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const endpoint = `company/company/${id}`;
  const referenceEndPoint = `company/Project/GetCompanyProjects/${id}`;
  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        setCompany(response);
        setIsClaimable(response.isClaimable);
        setLoading(false);
        setPageLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    apiService
      .get(referenceEndPoint)
      .then((response) => {
        setReferences(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  const navigate = useNavigate();
  useEffect(() => {
    const serviceGetUser = async () => {
      const userResponse = await props.authService.getUser();
      if (userResponse) {
        setIsSameCompany(userResponse.profile.sub == id ? true : false);
        setCompanyId(userResponse.profile.sub);
      } else {
        //navigate('/');
      }
      setUser(userResponse);
      return userResponse;
    };

    serviceGetUser();
  }, [props.authService, navigate]);
  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };
  const normalizeUrl = (url: string): string => {
    let normalizedUrl = url.trim().toLowerCase();

    if (
      !normalizedUrl.startsWith("http://") &&
      !normalizedUrl.startsWith("https://")
    ) {
      normalizedUrl = `https://${normalizedUrl}`;
    }

    if (!normalizedUrl.includes("www.")) {
      const urlParts = normalizedUrl.split("//");
      normalizedUrl = `${urlParts[0]}//www.${urlParts[1]}`;
    }

    return normalizedUrl;
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  if (loading) {
    return (
      <>
        <CompanyBanner companyLogo={logo} />
        <main className="container">
          <div className="detailContainer">
            <Spinner />
          </div>
        </main>
      </>
    );
  }
  const comanyLogo =
    company && company.profilePictureUrl
      ? company.profilePictureUrl.includes("Uploads")
        ? `${company.profilePictureUrl}`
        : company.profilePictureUrl
      : logo;
  const foundedYear =
    company && company.foundedIn
      ? new Date(company.foundedIn).getFullYear()
      : null;
  const websiteUrl =
    company && company.website && company.website.startsWith("www")
      ? "http://" + company.website
      : company?.website;

  return (
    <>
      <CompanyBanner
        companyLogo={comanyLogo}
        companyName={company?.name}
        companyLocation={company?.location}
        companyClaimable={company?.isClaimable}
      />

      <main className="container">
        {company !== null ? (
          <>
            <div className="row">
              <div className="col-lg-9">
                <div className="company-details">
                  <ul
                    className="nav nav-tabs col-md-8 mb-3 mt-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(0)}
                      >
                        <span className="d-none d-md-inline">Company </span>{" "}
                        Overview
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(1)}
                      >
                        Services{" "}
                        <span className="d-none d-md-inline">
                          {" "}
                          &amp; Technologies
                        </span>
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(2)}
                      >
                        Contact
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-4 mt-5">
                  {isSameCompany ? (
                    <Link
                      to="/edit-profile"
                      className="btn btn-outline-primary btn-sm"
                    >
                      Edit profile
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <div className="company-details mb-3">
                  <div className="tab-content" id="pills-tabContent">
                    {selectedTab === 0 && (
                      <div className="detail-pane">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Name</li>
                              <li>
                                {company.name ? company.name : "Confidential"}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Website</li>
                              <li>
                                {company.website ? (
                                  <a
                                    href={normalizeUrl(company.website)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {company.website}
                                  </a>
                                ) : (
                                  "Unspecified"
                                )}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Location</li>
                              <li>
                                {company.location
                                  ? company.location
                                  : "Undisclosed"}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Total Employees</li>
                              <li>
                                {company.size !== null
                                  ? CompanySize[company.size]
                                  : "Undisclosed"}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Founded</li>
                              <li>
                                {foundedYear ? foundedYear : "Undisclosed"}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Founded By</li>
                              <li>
                                {company.foundedBy
                                  ? company.foundedBy
                                  : "Undisclosed"}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="list-group list-group-horizontal-sm">
                              <li>Sector</li>
                              <li>
                                {company.industryType !== null
                                  ? IndustryType[company.industryType] ||
                                    "Unspecified"
                                  : "Unspecified"}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <p>{company.description}</p>
                        {references && references.length > 0 ? (
                          <>
                            <h6 className="my-4">Clients/Partners</h6>
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
                              {references.map((referenceData) => (
                                <div className="col" key={referenceData.id}>
                                  <ReferenceCard
                                    referenceData={referenceData}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {selectedTab === 1 && (
                      <div className="service-pane">
                        <h5>Services:</h5>
                        {company.services.length > 0 ? (
                          <ServicesList companyServices={company.services} />
                        ) : (
                          <p>
                            <button
                              className="nav-link text-primary"
                              onClick={() => handleTabChange(2)}
                            >
                              Contact for details
                            </button>
                          </p>
                        )}

                        <h5 className="mt-4">Technologies:</h5>
                        {company.technologies.length > 0 ? (
                          <TechnologiesList
                            companyTechnologies={company.technologies}
                          />
                        ) : (
                          <p>
                            <button
                              className="nav-link text-primary"
                              onClick={() => handleTabChange(2)}
                            >
                              Contact for details
                            </button>
                          </p>
                        )}
                      </div>
                    )}
                    {selectedTab === 2 && (
                      <div className="contact-pane">
                        {isSameCompany ? (
                          company && (
                            <>
                              {company.contactPersonName ? (
                                <p>
                                  <strong>Name</strong>
                                  {company.contactPersonName}
                                </p>
                              ) : (
                                <p>
                                  <strong>Name</strong>
                                  {company.name}
                                </p>
                              )}
                              {company.contactEmail ? (
                                <p>
                                  <strong>Email</strong>
                                  {company.contactEmail}
                                </p>
                              ) : (
                                <p>
                                  <strong>Email</strong>
                                  {company.primaryEmail}
                                </p>
                              )}
                              {company.contactPhoneNumber && (
                                <p>
                                  <strong>Phone</strong>
                                  {company.contactPhoneNumber}
                                </p>
                              )}
                            </>
                          )
                        ) : (
                          <Contact />
                        )}
                        {companyId == "" || (null && id && <Contact />)}
                      </div>
                    )}
                  </div>
                  <a
                    href="https://quantumcph.com/"
                    target="_blank"
                    className="d-inline-block"
                    rel="noreferrer"
                  >
                    <img src={bannerAd} alt="" className="img-fluid mt-3" />
                  </a>
                </div>
                {/* company-details */}
              </div>
              <div className="col-lg-3">
                <a href="mailto:info@askalva.com" className="d-inline-block">
                  <img src={alvaAds} alt="" className="img-fluid" />
                </a>
              </div>
            </div>
          </>
        ) : (
          <div className="row g-3 pt-4">
            <div className="col-lg-9">
              <div className="company-details mb-3  h-100">
                <div className="tab-content  h-100" id="pills-tabContent">
                  <p>No company data available.</p>
                </div>
              </div>
              {/* company-details */}
            </div>
            <div className="col-lg-3">
              <a href="mailto:info@askalva.com" className="d-inline-block">
                <img src={alvaAds} alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        )}
      </main>
      <Claim />
    </>
  );
};

export default CompanyDetail;
