import React, { useEffect, useState } from "react";
import eyeIcon from "./../../../assets/img/eye.svg";
import eyeSlashIcon from "./../../../assets/img/eye-slash.svg";
import "./ForgotPassword.scss";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { apiService } from "../../services/apiService";
import PageLoader from "../../shared/page-loader";
import Logo from "./../../assets/img/logo-colored.svg";
import tickmark from "./../../assets/img/tick-mark.svg";

interface ForgotPasswordResponse {
  statusCode: null | number;
  succeeded: boolean;
  errors: null | string;
  message: null | string;
  data: null | TokenResponse;
}
interface TokenResponse {
  accessToken: null | string;
  refreshToken: null | string;
}
interface ForgotPasswordForm {}
interface AuthProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
  };
}
const ForgotPasswordForm: React.FC<AuthProps> = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordResponse, setforgotPasswordResponse] =
    useState<ForgotPasswordResponse | null>(null);
  const [formSubmission, setFormSubmission] = useState(true);
  const navigate = useNavigate();

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please provide a valid email.");
    } else {
      setEmailError("");
      validateForm();
    }
  };

  useEffect(() => {
    if (email) validateEmail();
  }, [email]);

  const validateForm = () => {
    if (emailError === "" && email !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const endpoint = "auth/account/forgot-password";
    const postData = {
      Email: email,
    };

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        try {
          const response = await apiService.postIdentity(endpoint, {
            Email: email,
          });
          if (response) {
            setEmailSent(true);
          }
          setLoading(false);
          toast.success("reset password request sent.");
          //navigate(`/`);
          setFormSubmission(true);
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            // Conflict error
            toast.error("Company already exists!");
          } else {
            // Handle other specific error cases
            toast.error("An error occurred while signing up.");
          }
          setFormSubmission(true);
        }
      }, 1000); // 1000 milliseconds (1 second)
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = () => {
    navigate(`/signup`);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validateEmail();
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid onboarding">
        <div className="row">
          <div className="col-md-6 logo-section">
            <NavLink to="/">
              <img src={Logo} alt="" className="align-middle d-inline-block" />
            </NavLink>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4">
            {emailSent ? (
              <div className="content p-5">
                <div className="content p-5 text-center">
                  <div id="tick_mark">
                    <img src={tickmark} id="path_637" />
                  </div>
                  <h1>Email Sent!</h1>
                  <p className="text-muted mb-5">
                    Please check your email and Click on the link
                    <br />
                    to update your password!
                  </p>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="d-grid gap-2">
                      {formSubmission ? (
                        <button className="btn btn-primary" type="submit">
                          Resend
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-grow spinner-grow-sm"
                            aria-hidden="true"
                          ></span>
                          <span className="ms-2" role="status">
                            Resend
                          </span>
                        </button>
                      )}
                    </div>
                  </form>
                  <p className="mt-3 text-center">
                    <NavLink to="/">Back to home</NavLink>
                  </p>
                </div>
              </div>
            ) : (
              <div className="content p-5">
                <h1>Forgot Password?</h1>
                <p className="lead mb-5">
                  Please enter your registered email below to change your
                  password!
                </p>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      className={`form-control ${
                        emailError ? "is-invalid" : ""
                      }`}
                      required
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={validateEmail}
                      autoComplete="new-email"
                    />
                    {emailError && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}
                  </div>
                  <div className="d-grid gap-2">
                    {formSubmission ? (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={!isFormValid}
                      >
                        Reset
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-grow spinner-grow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2" role="status">
                          Reset
                        </span>
                      </button>
                    )}
                  </div>
                  <p className="mt-3 text-center">
                    <NavLink to="/">Back to home</NavLink>
                  </p>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
