import React, { useState, useEffect } from "react";
import { apiService } from "../../../services/apiService";
import CompaniesCard from "../../../shared/CompaniesCard";
import CompanyResponse from "../../../Models/CompanyResponse";
import SearchState from "../../../Models/SearchState";
import PageLoader from "../../../shared/page-loader";
const CompaniesList: React.FC = () => {
  const [state, setState] = useState<SearchState>({
    companyData: {} as CompanyResponse,
    isLoading: true,
    pageNumber: 1,
    PageSize: 6,
    OrderByColumn: "date",
    OrderBy: "ASC",
  });
  const [pageLoading, setPageLoading] = useState(true);
  const [moreCompanies, setMoreCompanies] = useState(true);
  const endpoint = `company/Company?PageNumber=1&PageSize=${state.PageSize}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;
  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        setState({
          ...state,
          companyData: response,
          isLoading: false,
          pageNumber: 1,
        });
        setPageLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
        console.error(error);
      });
  }, []);
  const handleLoadMore = () => {
    const newPageNumber = state.pageNumber + 1;
    const newEndpoint = `company/Company?PageNumber=${newPageNumber}&PageSize=${state.PageSize}&OrderByColumn=${state.OrderByColumn}&OrderBy=${state.OrderBy}`;
    apiService
      .get(newEndpoint)
      .then((response) => {
        setMoreCompanies(false);
        setTimeout(() => {
          setState({
            ...state,
            companyData: {
              items: [...state.companyData.items, ...response.items],
              pageNumber: response.pageNumber,
              totalPages: response.totalPages,
              totalCount: response.totalCount,
              hasPreviousPage: response.hasPreviousPage,
              hasNextPage: response.hasNextPage,
            },
            pageNumber: newPageNumber,
          });

          setMoreCompanies(true);
        }, 1000); // 1000 milliseconds (1 second)
      })
      .catch((error) => {
        console.error(error);
        setMoreCompanies(true);
      });
  };
  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <section className="near-you">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3">
          {state.companyData?.items?.length > 0 ? (
            state.companyData.items.map((companyData) => (
              <div className="col" key={companyData.id}>
                <CompaniesCard companyData={companyData} />
              </div>
            ))
          ) : (
            <p>No companies near you</p>
          )}
        </div>
        {state.companyData.hasNextPage && (
          <div className="d-grid gap-2 d-flex justify-content-center mt-5">
            {moreCompanies ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleLoadMore}
              >
                Show More
              </button>
            ) : (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  aria-hidden="true"
                ></span>
                <span className="ms-2" role="status">
                  Loading
                </span>
              </button>
            )}
          </div>
        )}
      </section>
    </>
  );
};
export default CompaniesList;
