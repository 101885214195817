import React, { useEffect, useState } from "react";
import { apiService } from "../../../services/apiService";
import "../EditProfile.scss";
import PageLoader from "../../../shared/page-loader";
interface POCFormProps {}
const Locations: React.FC<POCFormProps> = () => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [headOffice, setHeadOffice] = useState("");
  const [locationsNumber, setLocationsNumber] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState<null | "success">(
    null
  );
  const [errors, setErrors] = useState<{
    headOffice?: string;
    locationsNumber?: string;
  }>({});
  const id = "afasdfa-adfasfd-afdsasfd";
  const endpoint = `/company/company/${id}`;
  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        setPageLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
        console.error(error);
      });
  }, [id]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission
      // Log form data to console
      console.log("Form Data:", {
        headOffice,
        locationsNumber,
      });

      // Perform form submission logic here (e.g., send data to a server)

      // Simulating a successful submission
      // Replace the setTimeout function with your actual submission logic
      setTimeout(() => {
        // Show success message
        setSubmissionStatus("success");
      }, 2000);
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    let errors: {
      headOffice?: string;
      locationsNumber?: string;
    } = {};

    // Validate headOffice field
    if (!headOffice.trim()) {
      errors.headOffice = "Head office location is required";
    }
    // Validate locationsNumber field
    if (!locationsNumber.trim()) {
      errors.locationsNumber = "Number of Locations is required";
    }

    return errors;
  };
  // Clear the locationsNumber error when the input changes
  const handleLocationsNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocationsNumber(e.target.value);
    setErrors({ ...errors, locationsNumber: undefined });
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="row g-4">
        <div className="col-md-6">
          <label htmlFor="headOffice" className="form-label ms-4">
            Head Office Location
          </label>
          <input
            type="text"
            id="headOffice"
            className={`form-control ${errors.headOffice && "is-invalid"}`}
            value={headOffice}
            onChange={(e) => setHeadOffice(e.target.value)}
            placeholder="Head Office Location"
          />
          {errors.headOffice && (
            <div className="invalid-feedback">{errors.headOffice}</div>
          )}
        </div>
        <div className="col-md-6">
          <label htmlFor="locationsNumber" className="form-label ms-4">
            Number of Locations
          </label>
          <input
            type="text"
            id="locationsNumber"
            className={`form-control ${errors.locationsNumber && "is-invalid"}`}
            value={locationsNumber}
            onChange={handleLocationsNumberChange}
            placeholder="Number of Locations"
          />
          {errors.locationsNumber && (
            <div className="invalid-feedback">{errors.locationsNumber}</div>
          )}
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
          <button className="btn btn-primary" type="submit">
            Save changes
          </button>
        </div>
      </form>
    </>
  );
};

export default Locations;
