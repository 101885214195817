import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";
import "./Services.scss";
import CompanyLogo from "../CompanyLogo/CompanyLogo";
import PageLoader from "../../../shared/page-loader";

interface Service {
  id: string;
  name: string;
  createdBy: string | null;
  createdOn: string | null;
  requestUserId: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  description: string | null;
}
interface ChoosenServices {
  companyId: string | null;
  requestUserId: string | null;
  objectIds: any[];
}

export default function Services() {
  const [pageLoading, setPageLoading] = useState(true);
  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);
  const [companyServices, setCompanyServices] = useState<ChoosenServices[]>([]);
  const [userData, setUserData] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const endpoint = `company/Service`;
  const navigate = useNavigate();
  const [formSubmission, setFormSubmission] = useState(true);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const registerationId = params.get("id");

  useEffect(() => {
    const storedData = localStorage.getItem("userSignup");
    if (storedData !== null) {
      const items = JSON.parse(storedData);
      setUserData(items.objectId);
    }
  }, []);

  useEffect(() => {
    console.log(registerationId);
  }, [registerationId]);

  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        // Sort the servicesList alphabetically by name
        const sortedServices = response.sort((a: Service, b: Service) =>
          a.name.localeCompare(b.name)
        );
        setServicesList(sortedServices);
        setLoading(false);
        setPageLoading(false);
      })
      .catch((error) => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
      });
  }, []);

  const serviceOptions = servicesList.map((service) => ({
    value: service.id,
    label: service.name,
  }));

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedServices(selectedOptions);
    const selectedIds = selectedOptions.map((option: any) => option.value);

    const companyServicesLoad: ChoosenServices = {
      companyId: registerationId,
      requestUserId: registerationId,
      objectIds: selectedIds,
    };
    setCompanyServices([companyServicesLoad]);
  };

  const handleRedirection = () => {
    navigate("/signup/account-created");
  };

  const submitServices = async () => {
    const endpoint = "company/company/UpdateCompanyServices";
    const postData = companyServices[0];

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        const response = await apiService.put(endpoint, postData);
        console.log("POST Response:", response);
        setFormSubmission(true);
        toast.success("Services added successfully!");
        handleRedirection();
      }, 1000); // 1000 milliseconds (1 second)
    } catch (error) {
      console.error("POST Error:", error);
      toast.error("Updating failed!");
      setFormSubmission(true);
    }
  };

  const isSubmitDisabled = selectedServices.length === 0;

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <div className="services-content">
      <CompanyLogo></CompanyLogo>
      <h3>Select your services</h3>
      <p className="lead">Add at least one</p>
      <div className="form-group mb-3">
        {loading ? (
          <input
            type="text"
            className="form-control"
            disabled
            placeholder="Select"
          />
        ) : (
          <Select
            isMulti
            name="services"
            options={serviceOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary50: "#ffa142",
                primary25: "#ffbf80",
                primary: "#ff7f00",
              },
            })}
            onChange={handleSelectChange}
          />
        )}
      </div>

      <div className="d-grid gap-2">
        {formSubmission ? (
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitDisabled}
            onClick={submitServices}
          >
            Submit
          </button>
        ) : (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              aria-hidden="true"
            ></span>
            <span className="ms-2" role="status">
              Adding
            </span>
          </button>
        )}
      </div>
    </div>
  );
}
