import "../EditProfile.scss";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";
import { useLocation } from "react-router-dom";
import PageLoader from "../../../shared/page-loader";

interface Technology {
  id: string;
  name: string;
  createdBy: string | null;
  createdOn: string | null;
  requestUserId: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  description: string | null;
}
interface ChoosenTechnologies {
  companyId: string;
  requestUserId: string;
  objectIds: any[];
}

interface DefaultTechnologies {
  value: string;
  label: string;
}
interface TechnologiesFormProps {
  companyId: null | string;
}
const Technologies: React.FC<TechnologiesFormProps> = ({ companyId }) => {
  const [technologiesList, setTechnologiesList] = useState<Technology[]>([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<
    Technology[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [formSubmission, setFormSubmission] = useState(true);
  const [companyTechnologies, setCompanyTechnologies] = useState<
    ChoosenTechnologies[]
  >([]);
  const [userTechnologies, setUserTechnologies] = useState<Technology[]>([]);
  const [defaultTechnologyOptions, setDefaultTechnologyOptions] = useState<
    DefaultTechnologies[]
  >([]);
  const [showTechnologySelection, setShowTechnologySelection] = useState(false);
  const endpoint = `company/Technology`;
  const webLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [webLocation]);

  useEffect(() => {
    setShowTechnologySelection(true);
  }, [defaultTechnologyOptions]);

  useEffect(() => {
    apiService
      .get(endpoint)
      .then((response) => {
        // Sort the technologiesList alphabetically by name
        const sortedTechnologies = response.sort(
          (a: Technology, b: Technology) => a.name.localeCompare(b.name)
        );
        setTechnologiesList(sortedTechnologies);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const id = companyId;
  useEffect(() => {
    if (id !== "" || null) {
      const companyEndpoint = `company/company/${id}`;
      apiService
        .get(companyEndpoint)
        .then((response) => {
          setUserTechnologies(response.technologies);
          setLoading(false);
          setPageLoading(false);
        })
        .catch((error) => {
          setTimeout(() => {
            setPageLoading(false);
          }, 1000);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    const matchingIndices = userTechnologies.map((companyTechnology) =>
      technologiesList.findIndex(
        (technology) => technology.id === companyTechnology.id
      )
    );

    // Create array of TechnologyOptions based on matching indices
    const defaultTechnologyOptions = matchingIndices.map(
      (index) => technologyOptions[index]
    );
    setDefaultTechnologyOptions(defaultTechnologyOptions);
  }, [technologiesList, userTechnologies]);

  const technologyOptions = technologiesList.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTechnologies(selectedOptions);
    if (id) {
      const selectedIds = selectedOptions.map((option: any) => option.value);
      const companyTechnologiesLoad = {
        companyId: id,
        requestUserId: id,
        objectIds: selectedIds,
      };
      setCompanyTechnologies([companyTechnologiesLoad]);
    }
  };

  const submitTechnologies = async () => {
    const endpoint = "company/company/UpdateCompanyTechnologies";
    const postData = companyTechnologies[0];

    setLoading(true);
    try {
      setFormSubmission(false);
      setTimeout(async () => {
        const response = await apiService.put(endpoint, postData);
        setFormSubmission(true);
        toast.success("Updated successfully!");
      }, 1000); // 1000 milliseconds (1 second)
    } catch (error) {
      console.error("POST Error:", error);
      setFormSubmission(true);
    }
  };

  const isSubmitDisabled = selectedTechnologies.length === 0;

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="form-group">
        {!showTechnologySelection ? (
          <>
            <label className="form-label ms-4">Add Technologies</label>
            <input
              type="text"
              className="form-control"
              disabled
              placeholder="Select"
            />
          </>
        ) : (
          <>
            <label className="form-label ms-4">Add Technologies</label>
            {defaultTechnologyOptions.length > 0 && (
              <Select
                placeholder="Select"
                isMulti
                name="technologies"
                defaultValue={defaultTechnologyOptions}
                options={technologyOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary50: "#ffa142",
                    primary25: "#ffbf80",
                    primary: "#ff7f00",
                  },
                })}
                onChange={handleSelectChange}
              />
            )}
            {defaultTechnologyOptions.length == 0 && (
              <Select
                placeholder="Select"
                isMulti
                name="technologies"
                defaultValue={defaultTechnologyOptions}
                options={technologyOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary50: "#ffa142",
                    primary25: "#ffbf80",
                    primary: "#ff7f00",
                  },
                })}
                onChange={handleSelectChange}
              />
            )}
          </>
        )}
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-5">
        {formSubmission ? (
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitDisabled}
            onClick={submitTechnologies}
          >
            Save changes
          </button>
        ) : (
          <button className="btn btn-primary" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              aria-hidden="true"
            ></span>
            <span className="ms-2" role="status">
              Saving
            </span>
          </button>
        )}
      </div>
    </>
  );
};

export default Technologies;
