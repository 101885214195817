import { useState } from "react";
import { Routes, NavLink, Route, Outlet, Navigate } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import Services from "../Signup/Services/Services";
import CompanyLogo from "../Signup/CompanyLogo/CompanyLogo";
import AccountCreated from "../Signup/AccountCreated/AccountCreated";
import Logo from "./../../assets/img/logo-colored.svg";
import "./Login.scss";

interface AuthProps {
  authService: {
    login: () => Promise<void>;
    logout: () => Promise<void>;
  };
}
const Login: React.FC<AuthProps> = (props) => {
  const [isCompanyLogin, setIsCompanyLogin] = useState<boolean>(true);

  const handleTabChange = (isCompany: boolean) => {
    setIsCompanyLogin(isCompany);
  };

  return (
    <div className="container-fluid onboarding">
      <div className="row">
        <div className="col-md-6 logo-section">
          <NavLink to="/">
            <img src={Logo} alt="" className="align-middle d-inline-block" />
          </NavLink>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4">
          <div className="content p-5">
          <LoginForm authService={props.authService} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
