import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { apiService } from "../../../services/apiService";

interface ReferencesDeleteProps {
  companyId: string;
  referenceId: string | null;
  onCloseModal: () => void; // Function to close the modal
}

const ReferencesDelete: React.FC<ReferencesDeleteProps> = ({
  companyId,
  referenceId,
  onCloseModal,
}) => {
  const [formSubmission, setFormSubmission] = useState(true);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (referenceId) {
      setFormSubmission(true);
    }
  }, [referenceId]);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmission(false);
    setTimeout(async () => {
      try {
        const endpoint = "company/project";
        await apiService.deleteReference(endpoint, referenceId || "");
        toast.success("Reference removed successfully!");
      } catch (error) {
        toast.error("Failed to remove reference!");
      }
      setFormSubmission(true);
      handleCloseModal();
    }, 1000);
  };
  const handleCloseModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="deleteReferenceBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="deleteReferenceBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form onSubmit={handleSubmit} className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="deleteReferenceBackdropLabel"
              >
                Remove Reference
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
                onClick={onCloseModal}
                disabled={!formSubmission}
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to remove this reference?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                onClick={onCloseModal}
                disabled={!formSubmission}
              >
                Cancel
              </button>
              {formSubmission ? (
                <button className="btn btn-danger" type="submit">
                  Remove
                </button>
              ) : (
                <button className="btn btn-danger" type="button" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="ms-2" role="status">
                    Removing
                  </span>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReferencesDelete;
